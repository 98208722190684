import React, { Fragment, PureComponent } from 'react'
import { InputGroup, Input, Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import { CustomAsyncSelect } from 'shared/forms'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class ClientStorageDealsHistorySearchAndFilters extends PureComponent {
    constructor(props) {
        super(props)
        this.dateFormat = 'yyyy/MM/dd'
        this.minDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3,
            new Date().getDate()
        )
    }

    render() {
        const {
            onSearchProviderOrProposalUUID,
            onSearchProviderOrProposalUUIDKeyDown,
            onSearchDate,
            onSearchOnChainStatus,
            onSearchExternalDecision,
            dealOnChainStatusSelected,
            externalDecisionSelected,
            t,
        } = this.props

        return (
            <Fragment>
                <Row className="mt-4">
                    <Col xs={12} md={12} lg={12} className="mb-2">
                        <InputGroup>
                            <Input
                                id="search"
                                placeholder={t('inputSearch.placeholder')}
                                onKeyDown={onSearchProviderOrProposalUUIDKeyDown}
                                onChange={(e) => onSearchProviderOrProposalUUID(e.target.value)}
                                className="form-control"
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="mt-2" style={{ marginBottom: 50 + 'px' }}>
                    <Col xs={12} md={4} lg={4} className="mt-2 mt-md-0">
                        <DatePicker
                            disabledKeyboardNavigation
                            dateFormat={this.dateFormat}
                            selectsRange={true}
                            monthsShown={2}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            onChange={(value) =>
                                onSearchDate(value[0], value[1])
                            }
                            isClearable={true}
                            className="form-control"
                            maxDate={new Date()}
                            minDate={this.minDate}
                            placeholderText={t('dateRangePicker.placeholder', {
                                dateFormat: this.dateFormat,
                            })}
                        />
                    </Col>

                    <Col xs={12} md={4} lg={4}>
                        <CustomAsyncSelect
                            onChange={onSearchExternalDecision}
                            id="externalDecision"
                            name="externalDecisionName"
                            placeholder={t('externalDecision.placeholder')}
                            data={[
                                { 
                                    label: t('externalDecision.all'), 
                                    value: 'all' 
                                },
                                { 
                                    label: t('externalDecision.accept'), 
                                    value: 'accept' 
                                },
                                { 
                                    label: t('externalDecision.reject'), 
                                    value: 'reject' 
                                },
                                { 
                                    label: t('externalDecision.busy'), 
                                    value: 'busy' 
                                },
                                { 
                                    label: t('externalDecision.serviceUnavailable'), 
                                    value: 'serviceUnavailable' 
                                },
                                { 
                                    label: t('externalDecision.clientNotAuthorized'), 
                                    value: 'clientNotAuthorized' 
                                },
                                { 
                                    label: t('externalDecision.clientNotEnoughtDatacap'), 
                                    value: 'clientNotEnoughtDatacap' 
                                },
                                { 
                                    label: t('externalDecision.dealTypeNotAccepted'), 
                                    value: 'dealTypeNotAccepted' 
                                },
                                { 
                                    label: t('externalDecision.invalidClientAddress'), 
                                    value: 'invalidClientAddress' 
                                },
                                { 
                                    label: t('externalDecision.pieceCidDuplicated'), 
                                    value: 'pieceCidDuplicated' 
                                },
                                { 
                                    label: t('externalDecision.startEpochTooEarly'), 
                                    value: 'startEpochTooEarly' 
                                },
                            ]}
                            defaultValue={
                                externalDecisionSelected === 'all'
                                    ? { label: t('externalDecision.all'), value: 'all' }
                                    : { label: externalDecisionSelected, value: externalDecisionSelected }
                            }
                        />
                    </Col>

                    <Col xs={12} md={4} lg={4}>
                        <CustomAsyncSelect
                            onChange={onSearchOnChainStatus}
                            id="onChainStatus"
                            name="onChainStatusName"
                            placeholder={t('onChainStatus.placeholder')}
                            data={[
                                {
                                    label: t('onChainStatus.all'),
                                    value: 'all',
                                },
                                { 
                                    label: t('onChainStatus.active'),
                                    value: 'active' 
                                },
                                {
                                    label: t('onChainStatus.published'),
                                    value: 'published',
                                },
                                {
                                    label: t('onChainStatus.slashed'),
                                    value: 'slashed',
                                },
                                {
                                    label: t('onChainStatus.expired'),
                                    value: 'expired',
                                },
                                {
                                    label: t('onChainStatus.publishedExpired'),
                                    value: 'published-expired',
                                },
                            ]}
                            defaultValue={
                                dealOnChainStatusSelected === 'all'
                                    ? { label: t('onChainStatus.all'), value: 'all' }
                                    : { label: dealOnChainStatusSelected, value: dealOnChainStatusSelected }
                            }
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default withTranslation('ClientStorageDealsHistorySearchAndFilters')(
    ClientStorageDealsHistorySearchAndFilters
)
